import '@rails/actiontext'
import ReactOnRails from 'react-on-rails'
import 'trix'

import Donation from '../components/Donation'
import '../stylesheets/application'

ReactOnRails.register({
  Donation
})
