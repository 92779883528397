import Button from '@mui/material/Button'
import React, { ReactElement } from 'react'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const Frequency = (): ReactElement => {
  const { state, dispatch } = useStore()

  const handleClick = (frequency): void => {
    dispatch({ type: 'SetFrequency', frequency })
  }

  const frequencyNameToString = (name: string): string => {
    switch (name) {
      case 'single_gift':
        return 'Just this once'
      case 'weekly':
        return 'Every week'
      case 'every_two_weeks':
        return 'Every two weeks'
      case 'monthly':
        return 'Every month'
      case 'annually':
        return 'Once a year'
    }
  }

  if (state.currentStep === Step.Frequency) {
    return (
      <Edit title="How often?">
        {state.frequencies.map(
          (frequency, index): ReactElement => (
            <Button
              key={frequency.frequency}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(): void => handleClick(frequency)}
              size="large"
              autoFocus={index === 0}
            >
              {frequencyNameToString(frequency.name)}
            </Button>
          )
        )}
      </Edit>
    )
  } else if (state.currentStep > Step.Frequency && state.appeal.recurring) {
    return (
      <Change
        step={Step.Frequency}
        message={
          <strong>
            {frequencyNameToString(
              state.donation.frequency.name
            ).toLocaleLowerCase()}
          </strong>
        }
      />
    )
  }
  return null
}

export default Frequency
