import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'
import { object, string } from 'yup'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const Email = (): ReactElement => {
  const { state, dispatch } = useStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const { handleSubmit, handleChange, handleBlur, values, isValid } = useFormik(
    {
      initialValues: {
        email: state.user.email || ''
      },
      validationSchema: object({
        email: string().email().required()
      }),
      onSubmit: async ({ email }) => {
        try {
          await axios.get('/users/exists', { params: { 'user[email]': email } })
          dispatch({ type: 'SetEmail', email, userExists: true })
        } catch (error) {
          if (error.response.status === 404) {
            dispatch({ type: 'SetEmail', email, userExists: false })
          }
        }
      }
    }
  )

  useEffect((): void => {
    if (state.currentStep === Step.Email && inputRef) {
      inputRef.current.focus()
    }
  }, [state.currentStep])

  if (state.currentStep === Step.Email) {
    return (
      <Edit title="Enter your email for confirmation" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          inputRef={inputRef}
          fullWidth
          required
          placeholder="test@example.com"
          slotProps={{ htmlInput: { 'aria-label': 'Email' } }}
        />
        <Button
          type="submit"
          disabled={!isValid}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Next
        </Button>
      </Edit>
    )
  } else if (state.currentStep > Step.Email && !state.user.id) {
    return (
      <Change
        step={Step.Email}
        message={
          <>
            and get confirmation at <strong>{state.user.email}</strong>
          </>
        }
      />
    )
  }
  return null
}

export default Email
