import { createTheme as muiCreateTheme } from '@mui/material/styles'

export default function createTheme(primaryColor: string) {
  return muiCreateTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            marginBottom: 0
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          focusRipple: false
        },
        styleOverrides: {
          root: {
            outlineOffset: '2px',
            outline: '2px solid transparent !important',
            '&:focus': {
              outline: `2px solid ${primaryColor} !important`
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: primaryColor
      }
    },
    typography: {
      fontFamily: '"Poppins", Helvetica, Arial, sans-serif',
      button: {
        fontWeight: 'bold',
        textTransform: 'none'
      }
    }
  })
}
