import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { ReactElement, ReactNode } from 'react'

import { Step, useStore } from '../../../lib/store'

type ChangeProps = {
  step: Exclude<Step, Step.Hidden>
  message: string | ReactNode
}

const Change = ({ step, message }: ChangeProps): ReactElement => {
  const { dispatch } = useStore()

  const handleClick = (): void => {
    dispatch({ type: 'SetStep', step })
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 2 }}>
      <IconButton onClick={handleClick} aria-label="Change">
        <EditIcon />
      </IconButton>
      <Typography flexGrow={1} data-testid="ChangeMessage">
        {message}
      </Typography>
    </Stack>
  )
}

export default Change
