import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useFormik } from 'formik'
import React, { ReactElement } from 'react'
import { boolean, object } from 'yup'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const Newsletter = (): ReactElement => {
  const { state, dispatch } = useStore()
  const { handleSubmit, handleChange, values, isValid } = useFormik({
    initialValues: {
      organizationReceiveNewsletter: true,
      appealReceiveNewsletter: true
    },
    validationSchema: object({
      organizationReceiveNewsletter: boolean(),
      appealReceiveNewsletter: boolean()
    }),
    onSubmit: ({ organizationReceiveNewsletter, appealReceiveNewsletter }) => {
      dispatch({
        type: 'SetNewsletter',
        organizationReceiveNewsletter,
        appealReceiveNewsletter
      })
    }
  })

  if (state.currentStep === Step.Newsletter) {
    return (
      <Edit title="Receive newsletter updates?" onSubmit={handleSubmit}>
        {!state.userExists && (
          <FormControlLabel
            control={
              <Switch
                checked={values.organizationReceiveNewsletter}
                onChange={handleChange}
                name="organizationReceiveNewsletter"
                color="primary"
              />
            }
            label={`From ${state.organization.name}`}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={values.appealReceiveNewsletter}
              onChange={handleChange}
              name="appealReceiveNewsletter"
              color="primary"
            />
          }
          label={`From ${state.appeal.name}`}
        />
        <Button
          type="submit"
          disabled={!isValid}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          autoFocus
        >
          Next
        </Button>
      </Edit>
    )
  } else if (state.currentStep > Step.Newsletter) {
    return (
      <Change
        step={Step.Newsletter}
        message={
          <>
            {state.userExists ? (
              <>
                and{' '}
                <strong>
                  {state.donation.receiveNewsletter
                    ? 'receive appeal updates'
                    : 'receive no updates'}
                </strong>
              </>
            ) : (
              <>
                and{' '}
                <strong>
                  {state.user.receiveNewsletter &&
                    state.donation.receiveNewsletter &&
                    'receive all updates'}
                  {!state.user.receiveNewsletter &&
                    state.donation.receiveNewsletter &&
                    'receive appeal updates'}
                  {state.user.receiveNewsletter &&
                    !state.donation.receiveNewsletter &&
                    'receive organization updates'}
                  {!state.user.receiveNewsletter &&
                    !state.donation.receiveNewsletter &&
                    'receive no updates'}
                </strong>
              </>
            )}
          </>
        }
      />
    )
  }
  return null
}

export default Newsletter
