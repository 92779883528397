import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import React, { ReactElement } from 'react'

import { Step, useStore } from '../../../lib/store'
import Amount from '../Amount'
import Details from '../Details'
import Email from '../Email'
import Frequency from '../Frequency'
import Newsletter from '../Newsletter'
import PaymentProvider from '../PaymentProvider'
import Summary from '../Summary'

const Form = (): ReactElement => {
  const trigger = useScrollTrigger({
    threshold: 250,
    disableHysteresis: true
  })
  const { state, dispatch } = useStore()

  const handleClick = () => {
    dispatch({ type: 'Show' })
  }

  const handleClose = () => {
    dispatch({ type: 'Reset' })
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        size="large"
        fullWidth
        sx={{ height: 50 }}
      >
        Give Gift
      </Button>
      <Dialog
        fullScreen
        open={state.currentStep !== Step.Hidden}
        onClose={handleClose}
        scroll="paper"
        disableEscapeKeyDown
      >
        <DialogTitle sx={{ px: 0 }}>
          <Container maxWidth="sm">
            <Stack alignItems="center" direction="row" px={1}>
              <Box flexGrow={1}>Give a Gift to {state.appeal.name}</Box>
              <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Stack>
          </Container>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            px: 0
          }}
        >
          <Container maxWidth="sm">
            <Amount />
            <Frequency />
            <PaymentProvider />
            <Email />
            <Details />
            <Newsletter />
            <Summary />
          </Container>
        </DialogContent>
      </Dialog>
      {state.multiple === false && (
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            backgroundColor: (theme) => theme.palette.background.paper,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            transition: (theme) => theme.transitions.create('transform'),
            zIndex: 1,
            transform: trigger === false ? 'translateY(80px)' : undefined
          }}
        >
          <Container maxWidth="sm">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="large"
              fullWidth
              sx={{ height: 50 }}
            >
              Give Gift to {state.appeal.name}
            </Button>
          </Container>
        </Box>
      )}
    </>
  )
}

export default Form
