import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'
import { object, string } from 'yup'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const Details = (): ReactElement => {
  const { state, dispatch } = useStore()
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    setFieldValue
  } = useFormik({
    initialValues: {
      firstName: state.user.firstName || '',
      lastName: state.user.lastName || '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      region: '',
      countryCode: '',
      postalCode: ''
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      phoneNumber: string(),
      addressLine1: string(),
      addressLine2: string(),
      city: string(),
      region: string(),
      countryCode: string(),
      postalCode: string()
    }),
    onSubmit: async (values) => {
      dispatch({ type: 'SetDetails', values })
    }
  })

  useEffect((): void => {
    if (state.currentStep === Step.Details && inputRef) {
      inputRef.current.focus()
    }
  }, [state.currentStep])

  if (state.currentStep === Step.Details) {
    return (
      <Edit title="Enter your details" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField
              label="First Name"
              variant="outlined"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              inputRef={inputRef}
              fullWidth
              required
              slotProps={{ htmlInput: { 'aria-label': 'First Name' } }}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              fullWidth
              required
              slotProps={{ htmlInput: { 'aria-label': 'Last Name' } }}
            />
          </Grid>
        </Grid>
        <TextField
          label="Phone Number"
          variant="outlined"
          name="phoneNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          slotProps={{ htmlInput: { 'aria-label': 'Phone Number' } }}
          fullWidth
        />
        <TextField
          label="Address Line 1"
          variant="outlined"
          name="addressLine1"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.addressLine1}
          slotProps={{ htmlInput: { 'aria-label': 'Address Line 1' } }}
          fullWidth
        />
        <TextField
          label="Address Line 2"
          variant="outlined"
          name="addressLine2"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.addressLine2}
          slotProps={{ htmlInput: { 'aria-label': 'Address Line 2' } }}
          fullWidth
        />
        <Grid container spacing={2}>
          <Grid size={8}>
            <TextField
              label="City"
              variant="outlined"
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              slotProps={{ htmlInput: { 'aria-label': 'City' } }}
              fullWidth
            />
          </Grid>
          <Grid size={4}>
            <TextField
              label="Region / State"
              variant="outlined"
              name="region"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.region}
              slotProps={{ htmlInput: { 'aria-label': 'Region' } }}
              fullWidth
            />
          </Grid>
          <Grid size={8}>
            <Autocomplete
              onChange={(_e, value) => setFieldValue('countryCode', value)}
              options={[...Object.keys(state.countries), '']}
              value={values.countryCode}
              getOptionLabel={(option) => state.countries[option] || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid size={4}>
            <TextField
              label="Postal Code"
              variant="outlined"
              name="postalCode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postalCode}
              slotProps={{ htmlInput: { 'aria-label': 'Postal Code' } }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          fullWidth
          type="submit"
          disabled={!isValid}
          variant="contained"
          color="primary"
          size="large"
        >
          Next
        </Button>
      </Edit>
    )
  } else if (state.currentStep > Step.Details && !state.userExists) {
    return (
      <Change
        step={Step.Details}
        message={
          <>
            for{' '}
            <strong>
              {state.user.firstName.trim()} {state.user.lastName.trim()}
            </strong>
          </>
        }
      />
    )
  }
  return null
}

export default Details
