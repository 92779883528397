import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import React, { ReactElement, useEffect, useRef } from 'react'
import { NumericFormat } from 'react-number-format'
import { number, object } from 'yup'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const Amount = (): ReactElement => {
  const { state, dispatch } = useStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const { handleSubmit, handleChange, handleBlur, values, isValid } = useFormik(
    {
      initialValues: {
        amount: state.donation.amount !== '0' ? state.donation.amount : ''
      },
      validationSchema: object({
        amount: number()
          .required()
          .positive()
          .test(
            'maxDigitsAfterDecimal',
            'amount field must have 2 digits after decimal or less',
            (number) => Number.isInteger(number * 10 ** 2)
          )
      }),
      onSubmit: ({ amount }) => {
        dispatch({ type: 'SetAmount', amount })
      }
    }
  )

  useEffect((): void => {
    if (state.currentStep === Step.Amount && inputRef) {
      inputRef.current.focus()
    }
  }, [state.currentStep])

  if (state.currentStep === Step.Amount) {
    return (
      <Edit title="How much would you like to give?" onSubmit={handleSubmit}>
        <TextField
          label="Amount"
          variant="outlined"
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          inputRef={inputRef}
          fullWidth
          required
          placeholder="100"
          type="number"
          slotProps={{
            htmlInput: { 'aria-label': 'Amount', step: 10 },
            input: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }
          }}
        />
        <Button
          type="submit"
          disabled={!isValid}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Next
        </Button>
      </Edit>
    )
  } else if (state.currentStep > Step.Amount) {
    return (
      <Change
        step={Step.Amount}
        message={
          <>
            I&apos;d like to give{' '}
            <strong>
              <NumericFormat
                value={state.donation.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </strong>
          </>
        }
      />
    )
  }
  return null
}

export default Amount
