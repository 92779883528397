import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { FormEvent, ReactElement, ReactNode } from 'react'

type ChangeProps = {
  title: string
  children: ReactNode
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void
}

const Edit = ({
  title,
  children,
  onSubmit: handleSubmit
}: ChangeProps): ReactElement => (
  <Card>
    <CardContent>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h6">{title}</Typography>
          {children}
        </Stack>
      </form>
    </CardContent>
  </Card>
)

export default Edit
