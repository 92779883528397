import { ThemeProvider } from '@mui/material/styles'
import React, { ReactElement } from 'react'

import createTheme from '../../lib/createTheme'
import { State, Step, StoreProvider } from '../../lib/store'
import Form from './Form'

type DonationProps = {
  initialState: Omit<State, 'currentStep'>
}

const Donation = ({ initialState }: DonationProps): ReactElement => {
  return (
    <StoreProvider initialState={{ ...initialState, currentStep: Step.Hidden }}>
      <ThemeProvider
        theme={createTheme(initialState.organization.primaryColor)}
      >
        <Form />
      </ThemeProvider>
    </StoreProvider>
  )
}

export default Donation
