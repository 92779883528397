import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { ReactElement } from 'react'

import { Step, useStore } from '../../../lib/store'
import Change from '../Change'
import Edit from '../Edit'

const PaymentProvider = (): ReactElement => {
  const { state, dispatch } = useStore()

  const handleClick = (paymentProvider): void => {
    dispatch({ type: 'SetPaymentProvider', paymentProvider })
  }

  const recurring = (): boolean => {
    return parseFloat(state.donation?.frequency?.frequency) > 0
  }

  const paymentProviders = state.paymentProviders.filter((paymentProvider) =>
    recurring() ? paymentProvider.recurringGift : paymentProvider.singleGift
  )

  if (state.currentStep === Step.PaymentProvider) {
    return (
      <Edit title="How do you want to give?">
        {paymentProviders.map((paymentProvider, index): ReactElement => {
          return (
            <Box key={paymentProvider.id}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(): void => handleClick(paymentProvider)}
                size="large"
                autoFocus={index === 0}
              >
                {paymentProvider.name}
              </Button>
              {parseFloat(paymentProvider.percentage) > 0 &&
                parseFloat(paymentProvider.base) > 0 && (
                  <Typography
                    style={{ textAlign: 'center' }}
                    variant="body2"
                    color="textSecondary"
                  >
                    {new Intl.NumberFormat('en-US', {
                      style: 'percent',
                      maximumFractionDigits: 1
                    }).format(
                      parseFloat(paymentProvider.percentage) / 100
                    )}{' '}
                    +{' '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    }).format(parseFloat(paymentProvider.base))}{' '}
                    convenience fee
                  </Typography>
                )}
            </Box>
          )
        })}
      </Edit>
    )
  } else if (state.currentStep > Step.PaymentProvider) {
    return (
      <Change
        step={Step.PaymentProvider}
        message={
          <>
            using{' '}
            <strong>
              {state.donation.paymentProvider.name.toLocaleLowerCase()}
            </strong>
          </>
        }
      />
    )
  }
  return null
}

export default PaymentProvider
